import React from 'react';
import { Col, Layout, Row } from 'antd';
import styles from './style.module.scss';
import FeaturedMenu from './featuredMenu/FeaturedMenu';
import NumberAccesses from './NumberAccess/NumberAccesses';
// import OtherWebPages from './otherWebPages/OtherWebPages';
import Information from './Information/information';
// import Comment from '../components/Comment/Comment';
import RadarCharts from './RadarCharts/radarCharts';
// import HeaderBanner from './headerBanner/HeaderBanner';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import SearchSection from './searchSection/searchSection';
// import HeaderThreeCharts from './headerTitle/HeaderThreeCharts';

const { Content } = Layout;

export default function HomePage() {
  return (
    <Layout className={styles.homePageLayout}>
      {/* <MetaInfo title="Шилэн данс - Нүүр хуудас" /> */}
      <Content>
        {/* <HeaderBanner /> */}
        <SearchSection />
        {/* <HeaderThreeCharts /> */}
        <RadarCharts RadarChartId="HomepageRadarChart" />
        <FeaturedMenu />
        <Row justify="center">
          <Col {...CONTENT_SPAN}>
            <NumberAccesses />
            <Information />
            {/* <OtherWebPages /> */}
            {/* <Comment /> */}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

