import React from 'react';
import { EyeFilled } from '@ant-design/icons';
import { isEmpty, last } from 'lodash';
import { Tooltip } from 'antd';
import moment from 'moment';
import { FORMAT, REPORT_STATUS } from '../../../../constants/ReportConstants';
import { downloadFile } from '../../../../service/fileService';
import { DATEFORMAT } from '../../../../constants/Constant';
import {
  convertCurrencyFormat,
  getDifferenceInDays,
} from '../../../../tools/Tools';
import ReportStatus from './ReportStatus';
import style from './style.module.scss';

const ReportTemplateGetHeader = (selectedReportForm, orgRecurring) => {
  const templateHeader = selectedReportForm.reportFormHeaders;
  const columns = [];

  if (isEmpty(templateHeader)) {
    return [];
  }

  const dateField = templateHeader.find(
    header => header.format === FORMAT.DATE
  )?.col;

  const firstLineHeaders = templateHeader.filter(
    header => header.line === 1 && !header.isCode
  );

  const getChildren = (parent, parentKey) => {
    const startColIndex = parent.colIndex;
    const endColIndex = parent.colIndex + Number(parent.mergeColQuantity || 1);
    const childLine = templateHeader.filter(
      header =>
        header.line === parent.line + 1 &&
        header.colIndex >= startColIndex &&
        header.colIndex < endColIndex
    );
    // eslint-disable-next-line no-use-before-define
    const children = childLine.map(field => getColumn(field, parentKey));
    return children;
  };

  const getColumn = (field, parentKey) => {
    const key = parentKey ? `${parentKey}-${field.col}` : field.col;
    const column = {
      title: field.data,
      dataIndex: field.col,
      key,
      resizeColKey: key,
      width: field.width || 150,
      excelRender: cellValue => {
        if (field.format === FORMAT.SELECT && !isEmpty(field.selectableList)) {
          const selectLabel = field.selectableList.find(
            select => select.value === cellValue
          );
          return selectLabel?.label || cellValue;
        }
        return cellValue;
      },
      render: cellValue => {
        if (field.format && !field.isSpecification) {
          switch (field.format) {
            case FORMAT.FILE:
            case FORMAT.FILE_EXCEL: {
              if (!cellValue) return null;
              return (
                <div className={style.fileDownload}>
                  <Tooltip placement="left" title="Файл харах">
                    <EyeFilled onClick={() => downloadFile(cellValue.uuid)} />
                  </Tooltip>
                  <span style={{ overflowWrap: 'anywhere' }}>
                    {cellValue.name}
                  </span>
                </div>
              );
            }
            case FORMAT.DATE:
              return cellValue ? moment(cellValue).format(DATEFORMAT) : '';
            case FORMAT.CURRENCY:
              return cellValue ? `${convertCurrencyFormat(cellValue)} ₮` : '';
            case FORMAT.PERCENT:
              return cellValue ? `${cellValue} %` : '';
            case FORMAT.SELECT: {
              if (!isEmpty(field.selectableList)) {
                const selectLabel = field.selectableList.find(
                  select => `${select.value || ''}` === `${cellValue || ''}`
                );
                return selectLabel?.label || cellValue;
              }
              return '';
            }
            case FORMAT.LINK:
              return (
                <a
                  href={cellValue}
                  target="_blank"
                  rel="noreferrer"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {cellValue}
                </a>
              );
            default:
              return cellValue;
          }
        }
        return cellValue;
      },
    };
    if (field.format === FORMAT.CURRENCY || field.format === FORMAT.NUMBER) {
      column.align = 'right';
      column.sorter = (a, b) => a[field.col] - b[field.col];
    }
    if (field.format === FORMAT.PERCENT) {
      column.align = 'center';
    }
    if (field.format === FORMAT.DATE) {
      column.sorter = (a, b) => moment(a[field.col]).diff(moment(b[field.col]));
    }
    const childs = getChildren(field, key);
    if (!isEmpty(childs)) {
      const lastChild = last(childs);
      column.children = childs;
      column.resizeColKey = lastChild.resizeColKey;
      column.width = lastChild.width;
    }
    return column;
  };

  columns.push({
    title: '№',
    dataIndex: 'rowNo',
    key: 'rowNo',
    fixed: 'left',
    width: 40,
    align: 'right',
    __ignore__: true,
  });

  firstLineHeaders.forEach(field => {
    columns.push(getColumn(field));
  });

  if (selectedReportForm.isDynamic) {
    columns.push({
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 100,
    });
    columns.push({
      title: 'Төлөв',
      width: 60,
      align: 'center',
      fixed: 'right',
      __ignore__: true,
      render: (e, record) => {
        if (orgRecurring && orgRecurring?.isEvery === false) {
          return ReportStatus(REPORT_STATUS.SUCCESS)?.icon;
        }
        if (!record[dateField]) {
          return null;
        }
        const calcDateFirst = new Date(record[dateField]);
        const calcDateSecond = new Date(record.createdAt);
        const dayBetween = getDifferenceInDays(calcDateFirst, calcDateSecond);
        const reportStatus = ReportStatus(
          dayBetween > 7 ? REPORT_STATUS.EXPIRED : REPORT_STATUS.SUCCESS
        );
        return reportStatus?.icon;
      },
    });
  }
  return columns;
};

export default ReportTemplateGetHeader;

