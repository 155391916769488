import React, { useContext } from 'react';
import { Skeleton, Table, Typography } from 'antd';
import styles from './style.module.scss';
import OrgContext from '../../../../context/OrgContext';

const { Text } = Typography;

export default function AccountInformation() {
  const { orgInfo } = useContext(OrgContext);

  const height = document.getElementById('orgMainInfo').offsetHeight || 700;

  if (!orgInfo?.id) {
    return <Skeleton active />;
  }

  const dataSource = orgInfo?.orgBankAccounts || [];
  let dIndex = 1;

  dataSource.forEach(item => {
    item.key = dIndex;
    item.index = dIndex++;
  });

  const columns = [
    {
      title: '№',
      width: 60,
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Банкны нэр',
      dataIndex: 'refBankName',
      key: 'refBankName',
    },
    {
      title: 'Дансны нэр',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Дансны дугаар',
      dataIndex: 'iban',
      key: 'iban',
    },
    {
      title: 'Валют',
      dataIndex: 'refBankCurrencyName',
      key: 'refBankCurrencyName',
    },
    {
      title: 'Дансны зориулалт',
      dataIndex: 'purpose',
      key: 'purpose',
    },
    {
      title: 'Тооцооны төв байршил',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Орлого/Зарлага',
      dataIndex: 'description',
      key: 'description',
      render: text => (
        <Text strong className={styles.tabletext}>
          {text}Дэлгэрэнгүй
        </Text>
      ),
    },
  ];

  return (
    <div className={styles.accountInformationtable}>
      <Table
        rowKey={obj => obj.id}
        size="middle"
        className="table-striped-rows"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: height - 164 }}
      />
    </div>
  );
}
